<template>
  <v-dialog v-model="dialogForgotPassword" max-width="500">
    <template v-slot:activator="{ on }">
      <a slot="activator" class="text-center" @click="dialogForgotPassword = true" v-on="on">
        {{ $t(`Forgot password`) }}
      </a>
    </template>
    <v-form>
      <v-card>
        <v-card-title class="headline">{{ $t(`Enter your email address bellow`) }}: </v-card-title>

        <v-card-text>
          <label class="label ml-3">{{ $t("Email") }}</label>
          <v-text-field
            solo
            name="email"
            :label="$t(`Email`)"
            type="text"
            autofocus
            v-model="email"
            :rules="[rules.email.required, rules.email.validate]"
            @input="errors = []"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" small @click="submit">
            {{ $t(`Send`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-dialog>
</template>

<script>
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import { resetPassword } from "@/api";
export default {
  components: { ErrorsSnackbar, InfoSnackbar },
  data() {
    return {
      dialogForgotPassword: false,
      email: "",
      showWarnings: false,
      showInfo: false,
      infoMessage: "",
      warnings: [],
      rules: {
        email: {
          required: value => !!value || "Required.",
          validate: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        }
      }
    };
  },
  methods: {
    submit() {
      resetPassword({ email: this.email })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const self = this;
          self.showWarnings = false;
          self.infoMessage = this.$t("A verification link has been sent to your email.");
          self.showInfo = true;
          setTimeout(function() {
            self.dialogForgotPassword = false;
          }, 2000);
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
          this.showWarnings = true;
        });
    }
  }
};
</script>

<style></style>

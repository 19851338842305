<template>
  <v-form @submit="submit" class="px-12 shadow">
    <v-card-text class="px-12 pt-12">
      <label class="input-label ml-3">{{ $t(`Email`) }}</label>
      <v-text-field
        solo
        name="email"
        :label="$t(`Email`)"
        type="text"
        autofocus
        v-model.trim="email"
        :error-messages="error"
        :rules="[rules.email.required, rules.email.validate]"
      ></v-text-field>
      <label class="input-label ml-3">{{ $t(`Password`) }}</label>
      <v-text-field
        solo
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.password.required]"
        :type="showPass ? 'text' : 'password'"
        id="password"
        name="password"
        :label="$t(`Password`)"
        v-model.trim="password"
        @click:append="showPass = !showPass"
      ></v-text-field>
      <p class="text-center"><ForgotPasswordDialog></ForgotPasswordDialog></p>
    </v-card-text>
    <div class="social_auth_block">
      <v-row>
        <v-col class="social_text">{{ $t(`or login with`) }}</v-col>
      </v-row>
      <v-row>
        <v-col class="social_button text-right">
          <a @click="facebook_auth" class="social_button_link facebook"></a>
        </v-col>
        <v-col class="social_button">
          <a @click="google_auth" href="#" class="social_button_link google"></a>
        </v-col>
        <v-col class="social_button text-left">
          <a @click="linkedin_auth" href="#" class="social_button_link linkedin"></a>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="d-flex justify-center">
      <v-btn class="" color="primary" type="submit">{{ $t(`Login`) }}</v-btn>
      <!-- <v-btn class="" color="primary" type="submit">{{ $t(`Cancel`) }}</v-btn> -->
    </v-card-actions>

    <router-view />
  </v-form>
</template>
<script>
import { mapMutations } from "vuex";
import { login, loginViaToken, me } from "@/auth/auth";
import ForgotPasswordDialog from "@/pages/Auth/ForgotPasswordDialog";

export default {
  components: { ForgotPasswordDialog },
  data: () => ({
    email: "",
    password: "",
    loading: false,
    showPass: false,
    isAuthorised: me() || false,
    error: "",
    rules: {
      email: {
        required: value => !!value || "Required.",
        validate: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      password: {
        required: value => !!value || "Required."
      }
    }
  }),
  props: {
    source: String
  },
  methods: {
    facebook_auth() {
      window.open(
        `https://www.facebook.com/dialog/oauth?client_id=${process.env.VUE_APP_FACEBOOK_CLIENT_ID}&scopes=general_profile,email&redirect_uri=${process.env.VUE_APP_API_URL}/users/auth/facebook`
      );
    },

    google_auth() {
      window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${process.env.VUE_APP_API_URL}/users/auth/google_oauth2&client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}`
      );
    },

    linkedin_auth() {
      window.open(
        `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.VUE_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_API_URL}/users/auth/linkedin&state=foobar&scope=r_emailaddress`
      );
    },

    async submit(event) {
      event.preventDefault();

      try {
        await login({
          session: {
            email: this.email,
            password: this.password
          }
        }).then(() => {
          document.location.pathname = "/profile";
        });
        this.error = "";
      } catch (e) {
        this.error = e.response.data.errors[0];
      } finally {
        this.loading = false;
      }
    },
    ...mapMutations["setUserInfo"]
  },
  mounted() {
    if (this.isAuthorised) {
      this.$router.push("/");
    }

    const query = this.$route.query;
    if (query.error) {
      this.error = query.error;
    } else if (query.token) {
      loginViaToken(query.token)
        .then(() => {
          this.$router.push("/profile");
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.errors) {
            this.error = e.response.data.errors[0];
          } else {
            this.error = "Social network authorization token is wrong or has been expired";
          }

          const currentQuery = Object.assign({}, query);
          delete currentQuery.token;
          this.$router.replace({ currentQuery });
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.social_auth_block {
  & .social_text {
    color: #aeaeae;
    font-size: 14px;
    text-align: center;
  }

  & .social_button {
    text-align: center;

    & .social_button_link {
      position: relative;
      top: 0;
      right: 0;
      display: inline-block;
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100%;
    }

    & .facebook {
      background-image: url(~@/assets/icons/facebook_auth.png);
    }

    & .google {
      background-image: url(~@/assets/icons/google_auth.svg);
    }

    & .linkedin {
      background-image: url(~@/assets/icons/linkedin_auth.svg);
    }
  }
}
</style>
